import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { icons } from './assets/icons';

React.icons = icons;

ReactDOM.render(
    <App/>,
    document.getElementById('root'),
);

serviceWorker.unregister();
