import React, { Component } from 'react';
import {HashRouter, Route, Switch} from 'react-router-dom';
import './scss/style.scss';
import {SnackbarProvider} from 'notistack';

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse">&nbsp;</div>
    </div>
);

// Containers
const Layout = React.lazy(() => import('./containers/layout'));

// Pages
const Login = React.lazy(() => import('./views/auth/login'));
const Logout = React.lazy(() => import('./views/auth/logout'));
const ForgotPassword = React.lazy(() => import('./views/auth/forgot-password'));
const ResetPassword = React.lazy(() => import('./views/auth/reset-password'));
const Register = React.lazy(() => import('./views/auth/register'));
const LoginRegister = React.lazy(() => import('./views/auth/login-register'));
const ConfirmEmail = React.lazy(() => import('./views/auth/confirm-email'));
const CardAuth = React.lazy(() => import('./views/card-auth/card-auth'));
const CardAuthResult = React.lazy(() => import('./views/card-auth/card-auth-result'));

class App extends Component {

    render() {
        return (
            <SnackbarProvider
                maxSnack={ 3 }
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                autoHideDuration={ 7000 }>
                <HashRouter>
                    <React.Suspense fallback={loading}>
                        <Switch>
                            <Route exact path="/user/card-auth-result" name="Επιβεβαίωση" render={props => <CardAuthResult {...props}/>} />
                            <Route exact path="/user/card-auth" name="Επιβεβαίωση" render={props => <CardAuth {...props}/>} />
                            <Route exact path="/logout" name="Αποσύνδεση" render={props => <Logout {...props}/>} />
                            <Route exact path="/login" name="Σύνδεση" render={props => <Login {...props}/>} />
                            <Route exact path="/forgot-password" name="Ξέχασα τον κωδικό μου" render={props => <ForgotPassword {...props}/>} />
                            <Route exact path="/reset-password/:reset_token" name="Αλλαγή κωδικού" render={props => <ResetPassword {...props}/>} />
                            <Route exact path="/register" name="Εγγραφή νέου χρήστη" render={props => <Register {...props}/>} />
                            <Route exact path="/login-register" name="Έχω λογαριασμό - Δημιουργία λογαριασμού" render={props => <LoginRegister {...props}/>} />
                            <Route exact path="/login-register/:seminarId" name="Έχω λογαριασμό - Δημιουργία λογαριασμού" render={props => <LoginRegister {...props}/>} />
                            <Route exact path="/login-register/:seminarId/:periodId" name="Έχω λογαριασμό - Δημιουργία λογαριασμού" render={props => <LoginRegister {...props}/>} />
                            <Route exact path="/confirm-email/:token" name="Επιβεβαίωση email" render={props => <ConfirmEmail {...props}/>} />
                            <Route path="/" name="Αρχική" render={props => <Layout {...props}/>} />
                        </Switch>
                    </React.Suspense>
                </HashRouter>
            </SnackbarProvider>
        );
    }
}

export default App;
